import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import ImageVideoDemo from "../../static/media/image_daisy.webm";
import ImageVideoDemoFast from "../../static/media/image_daisy_fast.webm";
import ImageBirdDemo from "../../static/media/image_bird.webm";

import LogoCloud from "../assets/elements/LogoCloud";
import { StaticImage } from "gatsby-plugin-image";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { EncordGradientText } from "../utilities/DesignUtilities";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import { CASE_STUDIES } from "../partials/CaseStudyOverview";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Classification",
    description: "Apply nested and higher order classes to an entire image.",
    bullets: [
      "Self-driving cars",
      "Traffic surveillance",
      "Visual content moderation",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-classification.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Classification"}
      />
    ),
  },
  {
    name: "Object detection",
    description: "Recognise and localise objects with vector labeling tools.",
    bullets: [
      "Gastroenterology",
      "Automated retail checkout",
      "Drone surveillance",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-players.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Object detection"}
      />
    ),
  },
  {
    name: "Segmentation",
    description:
      "Assign a class to each pixel of an image with segmentation masks.",
    bullets: [
      "Stroke segmentation",
      "Pathology in microscopy",
      "Virtual fitting rooms",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-dicom-lungs.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Segmentation"}
      />
    ),
  },
];

export default function Image({ location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            <EncordGradientText text={"Automagic Image Segmentation Tool"} />{" "}
            For Faster Image Labeling
          </>
        }
        location={location}
        pageName="Image Segmentation"
        description={
          "Encord's image labeling platform helps you automate image segmentation with AI-assisted tooling, collaborative annotator management and quality assurance tools."
        }
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={
                  "https://encord.cdn.prismic.io/encord/25d2293f-3264-48e8-93f1-39f241b52263_auto-segment-hero-video-asset.webm"
                }
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
      />

      <LogoCloud
        title={
          "Trusted by groundbreaking computer vision teams around the world"
        }
        pb={10}
        pbMd={10}
        numImages={6}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://images.prismic.io/encord/bbe742ba-2b6e-4f9a-894e-1110b46e0daa_logo_royal_navy.webp?auto=compress,format"
            }
            alt={"royal navy logo"}
            className={"h-24 w-20"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/dca52008-c02a-4751-af64-181d69d82def_veo-landing-page.svg"
            }
            alt={"VEO logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageSix={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />
      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={
          "Instance and Semantic Segmentation Tool For Complex Computer Vision Projects"
        }
        description={
          <>
            <p>
              Encord is designed to handle advanced image segmentation projects
              and help you manage large annotation teams.
            </p>
            <br />
            <p>
              Encord is the only tool you need for efficient image segmentation
              and building active learning pipelines for advanced deep learning
              computer vision projects.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={ImageBirdDemo}
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/image_workflow.png"}
            width={450}
            alt="Annotator management at scale"
            className="rounded-lg"
          />
        }
        imageThree={
          <img
            src="https://images.prismic.io/encord/a8dca04f-cfa3-4407-9c39-91491be8a52f_ezgif.com-video-to-gif+%281%29.gif?auto=compress%2Cformat&fit=max"
            className="rounded-lg"
            width="768"
            height="432"
            alt="img"
          />
        }
        headerOne={"The Complete Image Segmentation Too"}
        headerTwo={"Annotator Management At Scale"}
        headerThree={"12x Image Annotation Speed With Automation"}
        descriptionOne={
          <>
            <p>
              Encord provides a single platform for all image annotation needs,
              including:
            </p>
            <br />
            <p>
              <ul>
                <li>
                  <span role="img" aria-label="white_check_mark">
                    ✅
                  </span>{" "}
                  AI-assisted segmentation
                </li>
                <li>
                  <span role="img" aria-label="white_check_mark">
                    ✅
                  </span>{" "}
                  Semantic and instance segmentation
                </li>
                <li>
                  <span role="img" aria-label="white_check_mark">
                    ✅
                  </span>{" "}
                  Polygon, polyline, keypoint and object primitive annotation
                  tools
                </li>
              </ul>
            </p>
            <br />
            <p>
              All of these features are native to the Encord platform, meaning
              you don’t need to use or maintain multiple custom image
              segmentation tools.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord’s collaborative annotator and data management dashboards
              make it much easier to scale large image annotation teams, whether
              in-house or outsourced.
            </p>
            <br />
            <p>
              You can automatically assign annotation tasks, monitor annotators’
              performance and set up custom quality control pipelines.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Auto image segmentation can help you reduce the time your
              annotators spend segmenting images.
            </p>
            <br />
            <p>
              Encord’s API helps you import model predictions. Our proprietary
              micro-models can also be used to pre-segment images so that your
              annotators only need to review the annotated data.
            </p>
          </>
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={CASE_STUDIES.STANFORD}
        title={"Use cases"}
        header={"Solve Any Image Labeling Task 10x Faster"}
        description={
          <>
            Encord's tools support image annotation for a variety of industries
            such as{" "}
            <CustomLink
              to={"/healthcare/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              healthcare
            </CustomLink>
            ,{" "}
            <CustomLink
              to={"/government/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              government
            </CustomLink>
            , and{" "}
            <CustomLink
              to={"/computer-vision/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              computer vision
            </CustomLink>
            .
          </>
        }
        useCases={useCases}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/image_ontology.webp"}
            width={400}
            alt="Image ontology for flowers"
            className="rounded-lg"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/image_code_sample.png"}
            width={400}
            alt="Build active learning for machine learning"
            className="rounded-lg"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/image_accuracy.webp"}
            width={550}
            alt="Encord helps you boost model accuracy"
            className="rounded-lg"
          />
        }
        headerOne={"Complex Image Labeling Ontologies"}
        headerTwo={"Automate Your  Model Development"}
        headerThree={"Better Quality Datasets"}
        descriptionOne={
          <>
            <p>
              Encord offers the industry’s most advanced labeling ontology. You
              can create as many nested feature types as you need to ensure your
              image annotation datasets provide the best ground truth for your
              computer vision models.
            </p>
            <br />
            <p>
              No matter how complex the image being labeled, our ontology can
              support the vision for your machine learning model.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord is the ideal platform for creating active learning
              pipelines, improving the efficiency of your projects and
              accelerating the development of your models.
            </p>
            <br />
            <p>
              Using Encord’s SDK and API, you can directly access projects,
              datasets and labels within the platform and automate annotation
              workflows and key features such as automated image annotation,
              training and inference.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Use Encord to identify the errors, biases and imbalances in your
              image annotation datasets.
            </p>
            <br />
            <p>
              Put in place granular QA workflows to ensure images are reviewed
              at the right level of accuracy for your use case.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Image Segmentation Page"}
        ctaText={
          "Get A Free Trial To See How Encord Helps Accelerate Model Development"
        }
      />
    </Layout>
  );
}

export const Head = () => (
  <SEO
    title="Encord | Automated image segmentation platform"
    description="Create high-quality image & video segmentation training data with Encord's flexible image annotation tooling."
  />
);
